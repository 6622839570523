import { useState } from 'react'
import { AiOutlineDeliveredProcedure } from 'react-icons/ai'
import { useDispatch } from 'react-redux'

import { useStateContext } from '../../state/context'
import { setCurrentLabTest } from '../../state/actions/actions'


const DoctorPatientTestsTable = ({ title, data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [showList, setShowList] = useState(true)

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <AiOutlineDeliveredProcedure className="text-dark-blue" size={20} />
                        </div>
                    </div>
                    {showList && (
                        <table className="border w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] px-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Category
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Sample
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((test, index) => (
                                    <tr key={`${test.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {test.test_type}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {test.test_category}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {test.test_sample}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {test.created_at.slice(0, 10)}
                                        </td>
                                        <td className="space-x-4 text-gray-900 font-light py-2 whitespace-nowrap">
                                            {test.is_local && !test.image_report && (
                                                <span className='text-[10px] text-yellow-500 font-medium italic'>Pending Investigation</span>
                                            )}

                                            {!test.is_local && !test.other_scanned_test_results && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 border border-yellow-600 text-yellow-600 text-[10px] font-medium leading-tight capitalize shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in"
                                                    onClick={() => {
                                                        dispatch(setCurrentLabTest({ data: test }))
                                                        setModals({ ...modals, showDoctorUploadOtherLabReportModal: !modals.showDoctorUploadOtherLabReportModal })
                                                    }}
                                                >
                                                    Upload Result
                                                </button>
                                            )}

                                            {test.image_report && test.is_local && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 border border-indigo-600 text-indigo-600 text-[10px] font-medium leading-tight capitalize shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in"
                                                    onClick={() => {
                                                        dispatch(setCurrentLabTest({ data: test }))
                                                        setModals({ ...modals, showDoctorWriteLabReportModal: !modals.showDoctorWriteLabReportModal })
                                                    }}
                                                >
                                                    Report
                                                </button>
                                            )}

                                            {test.other_scanned_test_results && !test.is_local && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 border border-indigo-600 text-indigo-600 text-[10px] font-medium leading-tight capitalize shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in"
                                                    onClick={() => {
                                                        dispatch(setCurrentLabTest({ data: test }))
                                                        setModals({ ...modals, showDoctorWriteLabReportModal: !modals.showDoctorWriteLabReportModal })
                                                    }}
                                                >
                                                    Report
                                                </button>
                                            )}

                                            {test.image_report && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 border-white text-white text-[10px] bg-teal-600 hover:bg-teal-400 font-medium leading-tight capitalize shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentLabTest({ data: test }))
                                                        setModals({ ...modals, showDoctorViewLabTestModal: !modals.showDoctorViewLabTestModal })
                                                    }}
                                                >
                                                    View
                                                </button>
                                            )}

                                            {test.other_scanned_test_results && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 border-white text-white text-[10px] bg-teal-600 hover:bg-teal-400 font-medium leading-tight capitalize shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentLabTest({ data: test }))
                                                        setModals({ ...modals, showDoctorViewLabTestModal: !modals.showDoctorViewLabTestModal })
                                                    }}
                                                >
                                                    View
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorPatientTestsTable
