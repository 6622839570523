import React, { Fragment } from "react";

const UserPosition = ({ user }) => {
  return (
    <Fragment>
      {user?.user_details?.is_patient && "Patient"}
      {user?.user_details?.is_records && "Records"}
      {user?.user_details?.is_doctor && "Doctor"}
      {user?.user_details?.is_nurse && "Nurse"}
      {user?.user_details?.is_pharmacist && "Pharmacist"}
      {user?.user_details?.is_radiologist && "Radiologist"}
      {user?.user_details?.is_laboratorist && "Laboratorist"}
      {user?.user_details?.is_audit && "Audit"}
      {user?.user_details?.is_administrator && "Admin"}
      {user?.user_details?.is_collections && "Collections"}
      {user?.user_details?.is_accountant && "Account"}
      {user?.user_details?.is_ophthalmologists && "Ophthalmologists"}
      {user?.user_details?.is_otolaryngologists && "Otolaryngologists"}
      {user?.user_details?.is_physiotherapists && "Physiotherapists"}
      {user?.user_details?.is_wife && "Mid Wife"}
      {user?.user_details?.is_dentist && "Dentist"}
      {user?.user_details?.is_secretory && "Secretory"}
    </Fragment>
  );
};

export default UserPosition;
