import { useState } from 'react'
import { FaListAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import { useStateContext } from '../../state/context'
import { setCurrentDiagnosis } from '../../state/actions/doctor.actions'


const DoctorPatientDiagnosisHistoryTable = ({ title, data }) => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const [showList, setShowList] = useState(true)

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <FaListAlt className="text-dark-blue" size={20} />
                        </div>
                    </div>
                    {showList && (
                        <table className="border w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] px-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Diagnosis
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Date Diagnosed
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((diagnosis, index) => (
                                    <tr key={`${diagnosis.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {diagnosis.diagnosis_name}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {diagnosis.created_at.slice(0, 10)}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-2 py-2 bg-purple-500 text-white font-medium text-xs leading-tight capitalize rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentDiagnosis({ data: diagnosis }))
                                                    setAppState({ ...appState, showDoctorPerformNewDiagnosis: !appState.showDoctorPerformNewDiagnosis })
                                                }}
                                            >
                                                Perform
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorPatientDiagnosisHistoryTable