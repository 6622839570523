import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setCurrentPatienFile } from '../../../state/actions/actions'


const DoctorViewFileModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { patient_file_update } = useSelector(state => state.root)

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-whit w-[600px]">
                <div className="flex flex-col">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'></h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setCurrentPatienFile({ data: null }))
                                setModals({ ...modals, showDoctorViewPatientFileModal: !modals.showDoctorViewPatientFileModal })
                            }}
                            size={20}
                        />
                    </div>

                    <div className="">
                        <img
                            src={patient_file_update?.file}
                            alt=""
                            className='cursor-pointer w-[600px] h-[600px] rounded-b-lg rounded-tl-lg'
                        />
                    </div>
                </div>


            </div>
        </div>
    )
}
export default DoctorViewFileModal
