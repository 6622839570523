import React from 'react'


const InventorySummary = ({ title, type, left_count, left_title, right_count, right_title }) => {
    return (
        <div className="py-2 w-[470px] text-dark-blue hover:translate-x-1 ease-in-out duration-500">
            <div className="flex justify-start items-center space-x-4 border border-gray-200 rounded-t-lg p-1 w-full">
                <h4 className="text-dark-blue text-[18px] font-bold">
                    {title && title}
                </h4>
            </div>

            <div className="w-full flex justify-between items-center border-b border-r border-l rounded-b-lg border-gray-200 py-5 px-4">
                <div className="flex flex-col items-start">
                    <h4 className='text-[16px]'>{left_count}</h4>
                    <p className='text-[14px]'>{left_title}</p>
                </div>
                {type !== 2 && (
                    <div className="flex flex-col items-start">
                        <h4 className='text-[16px]'>{right_count}</h4>
                        <p className='text-[14px]'>{right_title}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default InventorySummary