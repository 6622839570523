import React, { useState, useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useStateContext } from "../../state/context";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { setCallReceiver } from "../../state/actions/actions";

const ShowCallNotificationsModal = () => {
    const { call_receiver } = useSelector(state => state.root)
    const dispatch = useDispatch()
    const { me, setMe, getUserMedia, setGetUserMedia, name, setName, callAccepted, myVideo, userVideo, callEnded, stream, call, answerCall, leaveCall, modals, setModals, chatState, setChatState } = useStateContext()

    // useEffect(() => {
    //     setTimeout(setModals({ ...modals, showCallNotification: !modals?.showCallNotification }), 5000)
    // }, [])

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[450px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-blue text-[15px] font-bold">You placed a call to {call_receiver?.fullname}</h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setCallReceiver({ data: null }))
                                setModals({ ...modals, showCallNotification: !modals?.showCallNotification })

                            }}
                            size={20}
                        />
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ShowCallNotificationsModal