import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Video from './Video';

const VideoContainer = ({ isRoomMinimized }) => {

    const { localStream, remoteStreams, screenSharingStream } = useSelector(state => state.room);

    return (
        <div className='flex space-x-[15px] flex-wrap'>
            <Video stream={screenSharingStream ? screenSharingStream : localStream} isLocalStream isRoomMinimized={isRoomMinimized} />
            {remoteStreams.map((stream) => <Video stream={stream} key={stream.id} isRoomMinimized={isRoomMinimized} />)}



        </div>
    )
}
// grid grid-cols-4 gap-4

export default VideoContainer;