import reducer from "../../state/store";
import { setLocalStream, setRemoteStreams } from "../../state/actions/roomActions";
import Peer from 'simple-peer';
import { socket } from "./socketConnection";
import * as socketConnection from './socketConnection';

const getConfiguration = () => {
    const turnIceServer = null;

    if (turnIceServer) {

    } else {
        console.warn('Using only stern server');
        return {
            iceServers: [
                {
                    urls: 'stun:stun.l.google.com:19302'
                },
            ],
        }
    }
}

const onlyAudioConstraints = {
    audio: true,
    video: false
}

const defaultConstraints = {
    video: true,
    audio: true

}





export const getLocalStreamPreview = (onlyAudio = false, callbackFunc) => {
    const constraints = onlyAudio ? onlyAudioConstraints : defaultConstraints;
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        const localStream = {
            active
                :
                stream.active,
            id
                :
                stream.id,
            onactive
                :
                stream.onactive,
            onaddtrack
                :
                stream.onaddtrack,
            oninactive
                :
                stream.oninactive,
            onremovetrack
                :
                stream.onremovetrack
        }

        reducer.dispatch(setLocalStream(stream));
        console.log('local stream set', (typeof stream))
        callbackFunc();

    }
    ).catch(err => {
        console.log(err);
        console.log('cant access local stream ');
    });
}


let peers = {};


//Preparing peer connections


export const prepareNewPeerConnection = (connUserSocketId, isInitiator) => {
    const localStream = reducer.getState().room.localStream;

    if (isInitiator) {

        console.log('initiator')
    } else {
        console.log('not intiator');
    }

    peers[connUserSocketId] = new Peer({
        initiator: isInitiator,
        config: getConfiguration(),
        stream: localStream

    });

    peers[connUserSocketId].on('signal', data => {
        const signalData = {
            signal: data,
            connUserSocketId: connUserSocketId,
        };
        //pass signaling data to other users 
        socketConnection.signalPeerData(signalData);

    });
    peers[connUserSocketId].on('stream', (remoteStream) => {
        // add new remote stream to our server store
        console.log('remote stream came from other user');
        console.log('connection has been established');
        remoteStream.connUserSocketId = connUserSocketId;
        addNewRemoteStream(remoteStream);


    });
};


export const handleSignalingData = (data) => {

    const { connUserSocketId, signal } = data
    if (peers[connUserSocketId]) {
        peers[connUserSocketId].signal(signal);
    }
};


const addNewRemoteStream = (remoteStream) => {
    const remoteStreams = reducer.getState().room.remoteStreams;
    const newRemoteStreams = [...remoteStreams, remoteStream];
    reducer.dispatch(setRemoteStreams(newRemoteStreams));

};

export const closeAllConnections = () => {
    Object.entries(peers).forEach((mappedObject) => {
        const connUserSocketId = mappedObject[0];
        if (peers[connUserSocketId]) {
            peers[connUserSocketId].destroy();
            delete peers[connUserSocketId];
        }

    });

};

export const handleParticipantLeftRoom = (data) => {
    const { connUserSocketId } = data;
    if (peers[connUserSocketId]) {
        peers[connUserSocketId].destroy();
        delete peers[connUserSocketId];
    }

    // Removing  the stream of a user that just left 

    const remoteStreams = reducer.getState().room.remoteStreams;

    const newRemoteStreams = remoteStreams.filter((remoteStream) =>
        remoteStream.connUserSocketId !== connUserSocketId);
    reducer.dispatch(setRemoteStreams(newRemoteStreams));
};


export const switchOutgoingTracks = (stream) => {
    for (let socket_id in peers) {
        for (let index in peers[socket_id].streams[0].getTracks()) {
            for (let index2 in stream.getTracks()) {
                if (
                    peers[socket_id].streams[0].getTracks()[index].kind ===
                    stream.getTracks()[index2].kind
                ) {
                    peers[socket_id].replaceTrack(
                        peers[socket_id].streams[0].getTracks()[index],
                        stream.getTracks()[index2],
                        peers[socket_id].streams[0]
                    );
                    break;
                }
            }
        }
    }
};
