import React, { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import { getMonth } from '../utils/schedule_utils'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { setScheduleDate } from '../../state/actions/actions'
import { useDispatch, useSelector } from 'react-redux'

const SmallCalendar = (props) => {

    const currentMonthIndex = new Date().getMonth();
    const { set_Schedule_date } = useSelector(state => state.root)
    const dispatch = useDispatch()

    const [smallCalendardateIndex, setSmallCalendarDateIndex] = useState(currentMonthIndex)
    const [dayselected, setDayselected] = useState(null)
    const [currentMonth, setCurrentMonth] = useState(getMonth())


    function getDay(day) {
        const format = "DD-MM-YY";
        const nowDay = dayjs().format(format);
        const currDay = day.format(format);
        const selectedDay = dayselected && dayselected.format(format)
        if (nowDay === currDay) {
            return "bg-indigo-500 rounded-full text-white"
        } else if (currDay === selectedDay && set_Schedule_date != null) {
            return 'bg-blue-100 rounded-full text-blue-600 font-bold'
        }
        else {
            return ''
        }
    }

    useEffect(() => {
        setCurrentMonth(getMonth(smallCalendardateIndex))
    }, [smallCalendardateIndex])

    return (
        <div className='mt-5'>
            <header className='grid grid-cols-2'>
                <p className='text-sm text-gray-500 font-semibold mr-4'>
                    {dayjs(new Date(dayjs().year(), smallCalendardateIndex)).format("MMMM YYYY")}

                </p>
                <div className='flex ml-[100px]'>
                    <AiOutlineLeft className='cursor-pointer' onClick={() => setSmallCalendarDateIndex(smallCalendardateIndex - 1)} size={20} />
                    <AiOutlineRight className='cursor-pointer' onClick={() => setSmallCalendarDateIndex(smallCalendardateIndex + 1)} size={20} />
                </div>
            </header>

            <div className='flex-1 grid grid-cols-7 grid-rows-6 w-[280px]'>
                {currentMonth[0]?.map((day, i) => (
                    <span key={i} className='text-sm text-center'>
                        {day.format("dd").charAt(0)}
                    </span>
                ))}
                {currentMonth?.map((row, i) => (
                    <React.Fragment key={i}>
                        {row.map((day, index) => (
                            <button key={index} className={`px-2 w-full font-light  ${getDay(day)}`}
                                onClick={() => {
                                    setDayselected(day)
                                    const dayString = day['$d'].toString()
                                    const selected_month = parseInt(day['$M']) + 1
                                    const data = {
                                        stringDay: dayString.slice(0, 15),
                                        month: selected_month,
                                        current_day: day['$D'],
                                        year: day['$y'],
                                        day: day.valueOf()
                                    }
                                    dispatch(setScheduleDate({ data: data }))
                                }}
                            >
                                <span className='text-[10px]'>{day.format("D")}</span>
                            </button>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default SmallCalendar