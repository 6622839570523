import { Fragment } from 'react'
import Picker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ type, startDate, setStartDate, endDate, setEndDate }) => {

    return (
        <div>
            {type === 1 && (
                <Picker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    openToDate={new Date()}
                    className='border border-sky-300 bg-gray-100 rounded-lg h-[30px] w-[100px] pl-4 text-[12px]'
                />
            )}

            {type === 2 && (
                <Picker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    className='border border-sky-300 bg-gray-100 rounded-lg h-[30px] w-[100px] pl-4 text-[12px]'
                />
            )}

            {type === 3 && (
                <Picker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    className='border border-sky-300 bg-gray-100 rounded-lg h-[30px] w-[100px] pl-4 text-[12px]'
                />
            )}

            {type === 4 && (
                <div className='flex space-x-2'>
                    <Picker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className='border border-sky-300 bg-gray-100 rounded-lg h-[30px] w-[100px] pl-4 text-[12px]'
                    />
                    <Picker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MM/yyyy"
                        className='border border-sky-300 bg-gray-100 rounded-lg h-[30px] w-[100px] pl-4 text-[12px]'
                    />
                </div>
            )}
        </div>
    )
}

export default DatePicker