import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { VscFileSubmodule } from 'react-icons/vsc'
import { setCurrentPatienFile } from '../../state/actions/actions'

import { useStateContext } from '../../state/context'


const DoctorPatientFilesTable = ({ title, data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [showList, setShowList] = useState(true)

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <VscFileSubmodule className="text-dark-blue" size={20} />
                        </div>
                    </div>
                    {showList && (
                        <table className="border w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[60px] px-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[60px] text-left"
                                    >
                                        Title
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[60px] text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((file, index) => (
                                    <tr key={`${file.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {file.title}
                                        </td>
                                        <td className="py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {file.created_at.slice(0, 10)}
                                        </td>
                                        <td className="py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            <button
                                                type="button"
                                                className="inline-block px-4 py-2 border border-teal-100 text-teal-600 hover:bg-teal-200 hover:rounded-md font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentPatienFile({ data: file }))
                                                    setModals({ ...modals, showDoctorViewPatientFileModal: !modals.showDoctorViewPatientFileModal })
                                                }}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorPatientFilesTable
