import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    allNotifications,
    createNotification, getUserNotifications, readNotification
} from '../routes/notifications.routes'

export const createUserNotification = createAsyncThunk(
    'notification/createNotification',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await createNotification(formData, token)

            return data
        } catch (error) {
            console.log(error)

            return rejectWithValue(null)
        }
    }
)

export const getCurrentUserNotifications = createAsyncThunk(
    'notification/getCurrentUserNotifications',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getUserNotifications(id, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const UsersAllNotifications = createAsyncThunk(
    'notification/UsersAllNotifications',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await allNotifications(token)
            

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const readANotification = createAsyncThunk(
    'notification/readNotification',
    async ({ formData, id}, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await readNotification(formData, id, token)

            return data
        } catch (error) {
            console.log(error)

            return rejectWithValue(null)
        }
    }
)