import React, { Fragment, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import UserPosition from '../utils/UserPosition'
import { useStateContext } from '../../state/context'
import { setEmailReceiver } from '../../state/actions/actions'


const MessageUsersTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentUsers, setCurrentUsers] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div className="flex justify-between items-center space-x-4 bg-gray-200 p-1">
                        <div
                            className="flex justify-start items-center space-x-4"
                        >
                            <h4 className="text-dark-blue text-[14px] font-bold">
                                
                            </h4>
                            <FaUser className="text-dark-blue" size={20} />
                        </div>
                    </div> */}
                    <Fragment>

                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Position
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Gender
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Mail
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers?.map((user, index) => (
                                    <tr key={`${user.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.first_name} {user.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <UserPosition user={user} />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.gender}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.email}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                onClick={() => {
                                                    const data = {
                                                        first_name: user.first_name,
                                                        last_name: user.last_name,
                                                        id: user.user_id,
                                                        phone: user.patient_phone,
                                                        email: user.email,
                                                        user_pkid: user.pkid,
                                                    }
                                                    dispatch(setEmailReceiver({ data }))

                                                    setModals({ ...modals, showSendMailModal: !modals?.showSendMailModal })
                                                }}
                                            >
                                                Send Mail
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentUsers}
                            setCurrentData={setCurrentUsers}
                        />
                    </Fragment>
                </div>
            </div>
        </div>

    )
}

export default MessageUsersTable