import { Fragment, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { uploadPatientFile } from '../../../state/actions/doctor.actions'


const DoctorUploadPatientFilesModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { new_diagnosis } = useSelector(state => state.doctor)
    const [formData, setFormData] = useState({
        title: '', file: '', file_preview: '',
        patient: patient_update?.user, diagnosis: new_diagnosis?.pkid
    })

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleImageChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0], file_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(uploadPatientFile({ formData, toast, setModals, modals }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'>Upload Files</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                setModals({ ...modals, showDoctorUploadPatientFileModal: !modals.showDoctorUploadPatientFileModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder="File Title"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div className="flex flex-col items-center justify-center py-4 px-2 space-y-3 border rounded-md">
                                {typeof (formData.file) !== 'object' ? (
                                    <Fragment>
                                        {/* <p className='text-[11px] text-gray-400'>Upload your profile image</p> */}
                                        <AiOutlineCloudUpload
                                            size={40}
                                        />
                                        <input
                                            type="file"
                                            name='file'
                                            onChange={(e) => handleImageChange(e)}
                                            className="text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50 hover:file:text-amber-700"
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p className='text-[11px] text-gray-600'>Click to change</p>
                                        <img
                                            src={formData?.file_preview}
                                            alt="file"
                                            className='w-[50%] cursor-pointer'
                                            onClick={() => setFormData({ ...formData, file_preview: '', file: '' })}
                                        />
                                    </Fragment>
                                )}
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Upload</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}
export default DoctorUploadPatientFilesModal


