import React, { useState } from 'react'
import * as roomHandler from '../../config/groupcallConfig/roomHandler';
import { useDispatch, useSelector } from 'react-redux';

const ActiveRoomButton = (
    {
        creatorId,
        roomId,
        roomName,
        amountOfParticipants,
        isUserInRoom
    }
) => {

    const { user } = useSelector(state => state.auth)
    const [data, setData] = useState({

        user_id: '',
        fullname: '',
        email: '',
        roomID: ''
    })
    const userData = user?.data


    const handleJoinActiveRoom = () => {
        setData({
            ...data,
            user_id: user?.data?.id, fullname: `${user?.data.first_name} ${user?.data.last_name}`, email: user?.data?.email,
            roomID: roomId
        })
        if (amountOfParticipants < 6) {

            roomHandler.joinRoom(roomId, userData)

        }
    }


    const activeRoomButtonDisabled = amountOfParticipants > 3;
    const roomTitle = `Creator: ${creatorId}. connected ${amountOfParticipants}`;

    return (

        <button
            type='button'
            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
            disabled={activeRoomButtonDisabled || isUserInRoom}
            onClick={() => handleJoinActiveRoom()}
        >
            {roomName}
        </button>


    )
}

export default ActiveRoomButton