import { createSlice } from '@reduxjs/toolkit'

import { createUserNotification, getCurrentUserNotifications, UsersAllNotifications, readANotification} from '../actions/notifications.actions'


const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {

        create_notification:null,
        notifications: null,
        email_notification: null,
        notificationLoading: null,
        notificationRequestStatus: null,
    },
    extraReducers: {
        [createUserNotification.pending]: (state, action) => {
            state.notificationLoading = true
        },
        [createUserNotification.fulfilled]: (state, action) => {
            state.notificationLoading = false
            state.create_notification = action.payload
        },
        [createUserNotification.rejected]: (state, action) => {
            state.notificationLoading = false
        },
        [getCurrentUserNotifications.pending]: (state, action) => {
            state.notificationLoading = true
        },
        [getCurrentUserNotifications.fulfilled]: (state, action) => {
            state.notificationLoading = false
            // state.notifications = action.payload
        },
        [getCurrentUserNotifications.rejected]: (state, action) => {
            state.notificationLoading = false
        },
        [UsersAllNotifications.pending]: (state, action) => {
            state.notificationLoading = true
        },
        [UsersAllNotifications.fulfilled]: (state, action) => {
            state.notificationLoading = false
            state.notifications = action.payload
        },
        [UsersAllNotifications.rejected]: (state, action) => {
            state.notificationLoading = false
        },

        [readANotification.pending]: (state, action) => {
            state.notificationLoading = true
        },
        [readANotification.fulfilled]: (state, action) => {
            state.notificationLoading = false
            state.notifications = state.notifications.filter(notification=>notification.id !== action.payload.id)
        },
        [readANotification.rejected]: (state, action) => {
            state.notificationLoading = false
        },



    }
})


export default notificationSlice.reducer