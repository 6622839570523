import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'



const CalenderHeader = (props) => {
	const currentMonthIndex = new Date().getMonth();

	function handlePrevMonth() {
		props.setDateIndex(props.dateIndex - 1)
	}

	function handleNextMonth() {
		props.setDateIndex(props.dateIndex + 1)


	}
	return (
		<header className='flex justify-end items-center'>
			{/* <img src="src/assets/images/google_calendar.png" alt="" srcset="" className='w-[50px]' /> */}
			{/* <p className='mx-2 text-sm text-gray-500 font-bold'>Calender</p> */}
			<button
				type="button"
				className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
				onClick={() => {
					props.setDateIndex(currentMonthIndex)
				}}
			>
				Today
			</button>

			<div className='grid grid-cols-2 w-[250px] items-center'>
				<div className='flex'>
					<AiOutlineLeft className='cursor-pointer' onClick={() => handlePrevMonth()} />
					<AiOutlineRight className='cursor-pointer' onClick={() => handleNextMonth()} />
				</div>
				<span className='text-sm text-sky-600'>
					{dayjs(new Date(dayjs().year(), props.dateIndex)).format("MMMM YYYY")}
				</span>
			</div>
		</header>


	)
}

export default CalenderHeader