import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle, PharmacistPrescriptionsTable } from '../../components'
import { getPrescriptions } from '../../state/actions/pharmacist.actions'


const PharmacistPrescriptions = () => {
	const dispatch = useDispatch()

	const { prescriptions, prescriptions_invoice } = useSelector(state => state.pharmacist)
	const [showList, setShowList] = useState(true)

	useEffect(() => {
		dispatch(getPrescriptions())
	}, [prescriptions_invoice])

	return (
		<div>
			<PageTitle type={'pharmacist-prescriptions'} />

			<div className="space-y-5">
				<PharmacistPrescriptionsTable
					data={prescriptions}
					title={'Prescriptions'}
					showList={showList}
					setShowList={setShowList}
				/>
			</div>
		</div>
	)
}

export default PharmacistPrescriptions