import { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setCurrentLabTest, setCurrentRadiologyTest } from '../../../state/actions/actions'
import { updatePatientLabTest, updatePatientRadioTest } from '../../../state/actions/doctor.actions'


const DoctorAddRadiologyReportModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { patient_radiotest_update } = useSelector(state => state.root)
    const [formData, setFormData] = useState({ text_report: '', id: patient_radiotest_update?.id })

    useEffect(() => {
        setFormData({
            ...formData,
            text_report: patient_radiotest_update?.text_report ? patient_radiotest_update?.text_report : '',
            id: patient_radiotest_update?.id
        })
    }, [patient_radiotest_update])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(updatePatientRadioTest({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[20px] font-bold`}>
                            Update Test Report
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setCurrentRadiologyTest({ data: null }))
                                setModals({ ...modals, showDoctorWriteRadiologyReportModal: !modals.showDoctorWriteRadiologyReportModal })
                            }}
                            size={20}
                        />
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div>
                                <textarea
                                    cols="30"
                                    rows="7"
                                    name="text_report"
                                    placeholder="Report..."
                                    className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    value={formData.text_report}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                ></textarea>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
export default DoctorAddRadiologyReportModal
