import React, { useState, useEffect, useRef } from 'react'

const Video = ({ stream, isLocalStream, isRoomMinimized }) => {
    const videoRef = useRef();
    useEffect(() => {
        const video = videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
            video.play();

        }

    }, [stream])

    return (
        <div className={isRoomMinimized ? 'h-[40px] w-[70px] rounded' : 'h-[100px] w-[300px] ml-14 mr-14 mb-10'}>
            <video className='' ref={videoRef} autoPlay muted={isLocalStream ? true : false} > </video>

        </div>
    )
}

export default Video