import React, { Fragment, useState } from 'react'
import Pagination from '../utils/Pagination'

const SentMessagesTable = ({ data }) => {
    const [sentMessages, setSentMessages] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">Sent</h4>
                        <HiMail className="text-dark-blue" size={20} />
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sentMessages?.map((mails, index) => (
                                    <tr className="" key={mails.pkid}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {mails.receiver?.first_name}{" "}
                                            {mails.receiver?.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {mails.receiver?.email}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {mails.message}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={sentMessages}
                            setCurrentData={setSentMessages}
                        />
                    </Fragment>
                </div>
            </div>
        </div>

    )
}

export default SentMessagesTable