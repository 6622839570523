import { createSlice } from '@reduxjs/toolkit'

import { 
    addGroupChatParticipant,
    createChatConversation, createGroupConversation, getAllMessages, 
    getChatConversations, getCurrentChatMessages, getNextPageMessages, 
    getUserLastChat, getUsers, resetChatState, sendChatDocument, sendChatImage, sendChatMessage, 
    sendGroupChatDocument, 
    sendGroupChatImage, 
    sendGroupChatMessage, sendSingleChatMessage, setActiveChatState, 
    setCurrentChatConversation, setCurrentChatMessages, setCurrentChatUser, updateConversationMessages, updateGroupIcon,
} from '../actions/chat.actions'


const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        users: null,
        current_chatuser: null,
        current_chatconversation: null,
        current_chat_messages: null,
        paginated_chat_messages: null,
        chat_message: null,
        active_chat: null,
        user_lastchat: null,
        chat_messages: null,
        chat_conversation: null,
        groupchat_conversation: null,
        conversations_length: 0,
        chat_conversations: null,
        groupicon_update: null,
        groupchat_new_participant: null,
        chatLoading: null,
        chatErrors: null,
    },
    extraReducers: {
        [getUsers.pending]: (state, action) => {
            state.chatLoading = true
        },
        [getUsers.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.users = action.payload
        },
        [getUsers.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [setCurrentChatUser.pending]: (state, action) => {
            state.chatLoading = true
        },
        [setCurrentChatUser.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chatuser = action.payload
        },
        [setCurrentChatUser.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [setCurrentChatConversation.pending]: (state, action) => {
            state.chatLoading = true
        },
        [setCurrentChatConversation.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chatconversation = action.payload
        },
        [setCurrentChatConversation.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [getCurrentChatMessages.pending]: (state, action) => {
            state.chatLoading = true
        },
        [getCurrentChatMessages.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chat_messages = action.payload
        },
        [getCurrentChatMessages.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendChatMessage.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendChatMessage.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendChatMessage.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [setActiveChatState.pending]: (state, action) => {
            state.chatLoading = true
        },
        [setActiveChatState.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.active_chat = action.payload
        },
        [setActiveChatState.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [getUserLastChat.pending]: (state, action) => {
            state.chatLoading = true
        },
        [getUserLastChat.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.user_lastchat = action.payload
        },
        [getUserLastChat.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [setCurrentChatMessages.pending]: (state, action) => {
            state.chatLoading = true
        },
        [setCurrentChatMessages.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_messages = action.payload
        },
        [setCurrentChatMessages.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [getNextPageMessages.pending]: (state, action) => {
            state.chatLoading = true
        },
        [getNextPageMessages.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chat_messages['next'] = action.payload['next']
            state.current_chat_messages['previous'] = action.payload['previous']
        },
        [getNextPageMessages.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [getChatConversations.pending]: (state, action) => {
            state.chatLoading = true
        },
        [getChatConversations.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_conversations = action.payload
        },
        [getChatConversations.rejected]: (state, action) => {
            state.chatLoading = false
        },

        // [getReceiverChatConversations.pending]: (state, action) => {
        //     state.chatLoading = true
        // },
        // [getReceiverChatConversations.fulfilled]: (state, action) => {
        //     state.chatLoading = false
        //     state.receiver_chatconversations = action.payload
        // },
        // [getReceiverChatConversations.rejected]: (state, action) => {
        //     state.chatLoading = false
        // },

        [createChatConversation.pending]: (state, action) => {
            state.chatLoading = true
        },
        [createChatConversation.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_conversation = action.payload
            state.chat_conversations = [action.payload, ...state.chat_conversations]
        },
        [createChatConversation.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendSingleChatMessage.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendSingleChatMessage.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendSingleChatMessage.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [createGroupConversation.pending]: (state, action) => {
            state.chatLoading = true
        },
        [createGroupConversation.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload.message
            // state.groupchat_conversation = action.payload.data
            // state.chat_conversations = [action.payload.data, ...state.chat_conversations]
        },
        [createGroupConversation.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendGroupChatMessage.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendGroupChatMessage.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendGroupChatMessage.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendChatImage.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendChatImage.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendChatImage.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendChatDocument.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendChatDocument.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendChatDocument.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendGroupChatImage.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendGroupChatImage.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendGroupChatImage.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [sendGroupChatDocument.pending]: (state, action) => {
            state.chatLoading = true
        },
        [sendGroupChatDocument.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_message = action.payload
        },
        [sendGroupChatDocument.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [updateGroupIcon.pending]: (state, action) => {
            state.chatLoading = true
        },
        [updateGroupIcon.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.groupicon_update = action.payload
            state.current_chatuser = action.payload
        },
        [updateGroupIcon.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [addGroupChatParticipant.pending]: (state, action) => {
            state.chatLoading = true
        },
        [addGroupChatParticipant.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chatuser = action.payload
            state.groupchat_new_participant = action.payload
        },
        [addGroupChatParticipant.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [updateConversationMessages.pending]: (state, action) => {
            state.chatLoading = true
        },
        [updateConversationMessages.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.chat_readmessages = action.payload
        },
        [updateConversationMessages.rejected]: (state, action) => {
            state.chatLoading = false
        },

        [resetChatState.pending]: (state, action) => {
            state.chatLoading = true
        },
        [resetChatState.fulfilled]: (state, action) => {
            state.chatLoading = false
            state.current_chatuser = action.payload
            state.current_chatconversation = action.payload
            state.chat_conversations = action.payload
        },
        [resetChatState.rejected]: (state, action) => {
            state.chatLoading = false
        },
    }
})

export default chatSlice.reducer