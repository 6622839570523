import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'


const PharmacistDeleteSupplyPointModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { supplypoint_update } = useSelector((state) => state.root);

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(deleteSupplyPoint({ id: supplypoint_update?.id, toast, setModals, modals, dispatch, setSupplyPoint }));
    }
    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'>Delete</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setSupplyPoint({ data: null }))
                                setModals({ ...modals, showPharmacistDeleteSupplyPointModal: !modals.showPharmacistDeleteSupplyPointModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <p className="text-left">Are you sure you want to delete the supply point <span className='font-bold italic'>{supplypoint_update?.name}</span>?</p>

                        <div className='mt-4 flex justify-end'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-yellow text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Delete</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}
export default PharmacistDeleteSupplyPointModal


