import { useEffect } from 'react'
import {useNavigate} from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()

    useEffect(()=>{
        setTimeout(()=>{
            navigate('/', {replace: true})
        }, 5000)
    }, [])

    return (
        <div className="flex flex-col bg-indigo-400 h-screen items-center justify-center">
            <img
                src="/src/assets/images/art__404.svg" 
                alt="art__404"
                className='w-[600px]'    
            />
        </div>
    )
}

export default NotFound