import { API } from '../config'


export const fetchUsers = (token) => API.get('/chat/users_list/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchCurrentChatMessages = (params, token) => API.get(`/chat/messages/${params.conversation_id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateChatMessage = (formData, token) => API.post(`/chat/send_message/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchUserLastChatParticipant = (token) => API.get(`/chat/m_last_chat/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchChatHistories = (token) => API.get(`/chat/chat_histories/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllMessages = (token) => API.get(`/chat/all_messages/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchNextPageMessages = (url_patch, token) => API.get(`/chat/${url_patch}`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchChatConversations = (token) => API.get(`/chat/chat_conversation/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateChatConversation = (formData, token) => API.post(`/chat/chat_conversation/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateSingleChatMessage = (formData, token) => API.post(`/chat/sendchat_message/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateGroupConversation = (formData, token) => API.post(`/chat/groupchat_conversation/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const generateGroupWelcomeMessage = (formData, token) => API.post(`/chat/groupchat_welcomemessage/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateGroupChatMessage = (formData, token) => API.post(`/chat/send_groupchat_message/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateChatImage = (formData, token) => API.post(`/chat/sendchat_image/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const generateChatDocument = (formData, token) => API.post(`/chat/sendchat_document/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const patchGroupIcon = (formData, token) => API.patch(`/chat/update_group_icon/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const generateGroupParticipant = (formData, token) => API.patch(`/chat/add_group_participant/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchConversationMessages = (formData, token) => API.patch(`/chat/updatechat_messages/${formData.conversation_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
