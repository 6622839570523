import React from 'react'
import CalenderDay from './CalenderDay'

const CalenderMonth = ({ month }) => {

    return (
        <div className='flex-1 grid grid-cols-7 grid-rows-5 ml-8 mt-8' >
            {month.map((row, i) => (
                <React.Fragment key={i}>
                    {row.map((day, index) => (
                        <CalenderDay day={day} key={index} rowIndex={i} />
                    ))}
                </React.Fragment>
            ))}

        </div>
    )
}

export default CalenderMonth