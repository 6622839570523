import { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { getDoctors, transferPatient } from '../../../state/actions/doctor.actions'


const DoctorTransferPatientModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { user } = useSelector(state => state.auth)
    const { doctors } = useSelector(state => state.doctor)
    const [formData, setFormData] = useState({ doctor: '', patient: patient_update?.user, message: '' })

    useEffect(() => {
        dispatch(getDoctors())
    }, [])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(transferPatient({ formData, toast, setModals, modals }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'>Transfer Patient</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div>
                                <input
                                    type="text"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 bg-gray-300 placeholder:text-dark-blue hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    disabled
                                    value={`${patient_update?.details?.last_name} ${patient_update?.details?.first_name}`}
                                />
                            </div>

                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                    name="doctor"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value="">Doctor</option>
                                    {doctors?.filter((doc) => doc.pkid !== user?.data?.pkid).map(doc => (
                                        <option
                                            key={`${doc.id}`}
                                            value={doc.pkid}
                                        >
                                            Dr. {doc.last_name} {doc.first_name}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                            <div>
                                <textarea
                                    cols="30"
                                    rows="4"
                                    name="message"
                                    placeholder="Message"
                                    className="border rounded w-full px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                ></textarea>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}
export default DoctorTransferPatientModal


