import React, { Fragment, useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import { FaTimesCircle } from 'react-icons/fa'

import { useStateContext } from '../../state/context'


const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
}
const CapturePhotoModal = ({ formData, setFormData }) => {
    const { modals, setModals } = useStateContext()
    
    const webcamRef = React.useRef(null)

    const handleCapture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()

        var arr = imageSrc.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], 'pic.jpeg', { type: mime });

        setFormData({ ...formData, profile_photo: file, photo_display: imageSrc, webcamcapture: !formData.webcamcapture })

        if (typeof (file) === 'object') {
            setModals({ ...modals, showCapturePhotoModal: !modals.showCapturePhotoModal })
        }
    }, [webcamRef])



    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[400px] px-[30px] py-[20px] rounded">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-blue text-[20px] font-bold">
                            Capture Photo
                        </h4>
                        <FaTimesCircle
                            className="text-sky-500 cursor-pointer"
                            onClick={() => {
                                modals.showCapturePhotoModal && setModals({ ...modals, showCapturePhotoModal: !modals.showCapturePhotoModal })
                            }}
                            size={20}
                        />
                    </div>

                    <Webcam
                        audio={false}
                        height={800}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={800}
                        videoConstraints={videoConstraints}
                    />

                    <div className="mt-4 flex justify-center">
                        <button
                            type="submit"
                            className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                            onClick={handleCapture}
                        >
                            Capture
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CapturePhotoModal