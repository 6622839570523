import React, { useEffect, useState } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useStateContext } from "../../../state/context"
import { setBilling } from "../../../state/actions/actions"
import { createBilling, getAllBillings, updateBilling, } from "../../../state/actions/admin.actions"
import { issueOutDrug } from "../../../state/actions/pharmacist.actions"


const PharmacistIssueOutDrugModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { supplypoint_bincard } = useSelector(state => state.root)
    const [formData, setFormData] = useState({
        quantity_issued: "",
        supplypoint: "",
        issuing_drug: ""
    })

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            ...formData,
            supplypoint: supplypoint_bincard?.supplypointbincard_inventory?.supplypoint,
            issuing_drug: supplypoint_bincard?.supplypointbincard_inventory?.supplypoint_druginventory?.bincard_drug?.pkid
        }
        dispatch(issueOutDrug({ formData: data, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500 text-[20px] font-bold">
                            Issue Out
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                // dispatch(setBilling({ data: null }))
                                setModals({ ...modals, showPharmacistIssueOutDrugModal: !modals.showPharmacistIssueOutDrugModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            {/* <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Receipt Or Issue Voucher Number"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                            
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Received From Or Tssued To"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div> */}

                            <div>
                                <input
                                    type="number"
                                    name="quantity_issued"
                                    placeholder="Quantity"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistIssueOutDrugModal
