import { Fragment, useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setCurrentLabTest } from '../../../state/actions/actions'
import { updatePatientLabTest, uploadOtherPatientLabTest } from '../../../state/actions/doctor.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsImage } from 'react-icons/bs'


const DoctorUploadOtherTestReportModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { patient_labtest_update } = useSelector(state => state.root)
    const [formData, setFormData] = useState({ other_scanned_test_results: '', file_preview: '' })

    useEffect(() => {
        if (patient_labtest_update?.id) {
            setFormData({
                ...formData,
                id: patient_labtest_update?.id
            })
        }
    }, [patient_labtest_update])

    const handleImageChange = (e) => {
        setFormData({ ...formData, other_scanned_test_results: e.target.files[0], file_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(uploadOtherPatientLabTest({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[20px] font-bold`}>
                            Upload Laboratory Result
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setCurrentLabTest({ data: null }))
                                setModals({ ...modals, showDoctorUploadOtherLabReportModal: !modals.showDoctorUploadOtherLabReportModal })
                            }}
                            size={20}
                        />
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div className="flex flex-col items-center justify-center py-4 px-2 space-y-3 border rounded-md">
                                {formData.file_preview && (
                                    <div className='flex flex-col space-y-1 items-center relative'>
                                        {/* <p className='text-[12px] font-bold text-yellow-500'>Preview</p> */}
                                        <img
                                            src={formData?.file_preview}
                                            alt="file"
                                            className='w-[50%] cursor-pointer rounded-lg'
                                            onClick={() => setFormData({ ...formData, file_preview: '', file: '' })}
                                        />
                                    </div>
                                )}

                                <Fragment>
                                    <input
                                        type="file"
                                        name='other_scanned_test_results'
                                        onChange={(e) => handleImageChange(e)}
                                        className="text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50 hover:file:text-amber-700"
                                    />
                                </Fragment>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Upload</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
export default DoctorUploadOtherTestReportModal
