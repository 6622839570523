import React, { Fragment, useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { setProfileImage } from '../../state/actions/actions'
import { updateProfilePhoto } from '../../state/actions/auth.actions'

// import Buffer from 

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
}

const WebCamModal = () => {
    const dispatch = useDispatch()

    const webcamRef = React.useRef(null)
    const { modals, setModals } = useStateContext()

    const [image, setImage] = useState('')
    const [formData, setFormData] = useState({ profile_photo: '', photo_display: '' })
    const { image_capture } = useSelector(state => state.root)

    useEffect(() => {
        console.log(image_capture)
    }, [image_capture])

    // useEffect(() => {
    // }, [image])

    const handleCapture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        console.log(imageSrc);

        
        var arr = imageSrc.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file=  new File([u8arr], 'pic.jpeg', { type: mime });

        setFormData({ ...formData, profile_photo: file, photo_display: imageSrc })
    }, [webcamRef])



    const handleSubmit = () => {
        dispatch(updateProfilePhoto({ formData, toast, setModals, modals, type: 'webcam' }))
    }


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[400px] px-[30px] py-[20px] rounded">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-blue text-[20px] font-bold">
                            {modals.showWebCamModal ? 'Profile Image Capture' : 'Update billing type'}
                        </h4>
                        <FaTimesCircle
                            className="text-sky-500 cursor-pointer"
                            onClick={() => {
                                dispatch(setProfileImage({ data: null }))
                                    
                                modals.showWebCamModal && setModals({ ...modals, showWebCamModal: !modals.showWebCamModal })
                            }}
                            size={20}
                        />
                    </div>

                    {!formData.profile_photo && (
                        <Fragment>
                            <Webcam
                                audio={false}
                                height={800}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={800}
                                videoConstraints={videoConstraints}
                            />

                            <div className="mt-4 flex justify-center">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                    onClick={handleCapture}
                                >
                                    Capture
                                </button>
                            </div>
                        </Fragment>
                    )}

                    {formData.profile_photo && (
                        <Fragment>
                            <img src={formData.photo_display} alt="profile_image" />

                            <div className="flex justify-center space-x-5">
                                <div className="mt-4 flex justify-center">
                                    <button
                                        type="submit"
                                        className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                        onClick={handleSubmit}
                                    >
                                        Upload
                                    </button>
                                </div>
                                <div className="mt-4 flex justify-center">
                                    <button
                                        type="submit"
                                        className="inline-block w-[175px] px-6 py-2.5 bg-yellow-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                        onClick={() => setFormData({ ...formData, profile_photo: '' })}
                                    >
                                        Recapture
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    )}

                </div>
            </div>
        </div>
    )
}

export default WebCamModal