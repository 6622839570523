import { API } from "../config"


export const getUserNotifications = (id, token) => API.get(`/notification/unread_notification/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const createNotification = (formData, token) => API.post(`/notification/create_notification/${formData.notification_recipient}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const allNotifications = (token) => API.get(`/notification/all_notification/`, { headers: { Authorization: `Bearer ${token}` } })

export const readNotification = (formData, id, token) => API.put(`/notification/read_notification/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
